<template>
    <div id="accountInfoPg">
        <div class="main_area">
            <!-- ヘッダー -->
            <div class="header">

            </div>
            <!-- インフォ -->
            <div class="info_area">
                <div class="detail_area">
                    <div class="titlecell">社員番号</div>
                    <div class="datacell"><span>{{cvtEmploy(info.employNo)}}</span></div>
                    <div class="titlecell">氏名</div>
                    <div class="datacell"><span v-if="!editing">{{cvtName(info.name)}}</span><input v-if="editing" type="text" class="name" v-model="sendDt.name1"/><input v-if="editing" type="text" class="name" v-model="sendDt.name2"/></div>
                    <div class="titlecell">カナ氏名</div>
                    <div class="datacell"><span v-if="!editing">{{cvtName(info.kana)}}</span><input v-if="editing" type="text" class="kana" v-model="sendDt.kana1"/><input v-if="editing" type="text" class="kana" v-model="sendDt.kana2"/></div>
                    <div class="titlecell">生年月日</div>
                    <div class="datacell"><span v-if="!editing">{{cvtDate(info.birthDate)}}</span><input v-if="editing" type="date" v-model="sendDt.birthDate"/></div>
                    <div class="titlecell">入社日</div>
                    <div class="datacell"><span v-if="!chkEditmode">{{cvtDate(info.hireDate)}}</span><input v-if="chkEditmode" type="date" v-model="sendDt.hireDate"/></div>
                    <div class="titlecell">メールアドレス</div>
                    <div class="datacell"><span v-if="!chkEditmode">{{cvtEmail(info.email)}}</span><input v-if="chkEditmode" type="text" class="email" v-model="sendDt.email"/></div>
                    <div v-if="editing" class="titlecell">パスワード</div>
                    <div v-if="editing" class="datacell"><input v-if="editing" type="password" class="pwd" v-model="sendDt.pwd"/></div>
                    <div v-if="managed" class="titlecell">役職</div>
                    <div v-if="managed" class="datacell"><span v-if="!chkEditmode">{{postName}}</span>
                        <select v-if="chkEditmode" class="position" v-model="sendDt.postCd">
                            <option v-for="(item, idx) in positions" :value="item.code" :key="idx">{{item.name}}</option>
                        </select>
                    </div>
                    <div class="titlecell">権限</div>
                    <div class="datacell"><span v-if="!chkEditmode">{{authority}}</span>
                        <select v-if="chkEditmode" class="authority" v-model="sendDt.authority">
                            <option v-for="(item, idx) in options" :value="item.value" :key="idx">{{item.text}}</option>
                        </select>
                    </div>
                    <div v-if="managed" class="titlecell">給与明細</div>
                    <div v-if="managed" class="datacell"><span v-if="!editing">{{info.payslipFlag}}</span><label v-if="editing"><input type="checkbox" v-model="sendDt.payslipFlag"/>&nbsp;電子受け取りとする</label></div>
                    <div v-if="managed" class="titlecell">有効／無効</div>
                    <div v-if="managed" class="datacell"><span v-if="!editing">{{useFlag}}</span><label v-if="editing"><input type="checkbox" v-model="sendDt.useFlag"/>&nbsp;有効にする</label></div>
                    <div v-if="managed" class="titlecell">利用開始日</div>
                    <div v-if="managed" class="datacell"><span v-if="!editing">{{cvtDate(info.startDate)}}</span><input v-if="editing" type="date" v-model="sendDt.startDate"/></div>
                    <div v-if="managed" class="titlecell">利用終了日</div>
                    <div v-if="managed" class="datacell"><span v-if="!editing">{{cvtDate(info.endDate)}}</span><input v-if="editing" type="date" v-model="sendDt.endDate"/></div>
                    <div v-if="managed" class="titlecell">退職日</div>
                    <div v-if="managed" class="datacell"><span v-if="!chkEditmode">{{cvtDate(info.retireDate)}}</span><input v-if="chkEditmode" type="date" v-model="sendDt.retireDate"/></div>
                </div>
                <div class="list-area">
                    <div class="list1">
                        <div class="title-area"><div class="title">休職期間</div><div class="add" @click.stop="openKyushokuEditor(null)"><icon name="plus" class="icon"></icon></div></div>
                        <div class="kyushoku_area">
                            <div v-for="(item) in sendDt.kyushokuList" :key="item.startDate" class="kyushoku" @click.stop="openKyushokuEditor(item)"><div class="start">{{cvtDate(item.startDate)}}</div><div class="delim">〜</div><div class="end">{{cvtDate(item.endDate)}}</div><div class="comment">{{item.comment}}</div></div>
                        </div>
                    </div>
                    <div></div>
                    <div class="list2"></div>
                </div>
            </div>
            <!-- フッター -->
            <div class="footer">
                <button v-if="!editing" class="button hensyu" @click.stop="editSrat">編集</button>
                <button v-if="editing" class="button save" @click.stop="saveDataPre">登録</button>
                <button v-if="editing" class="button cancel" @click.stop="cancel">キャンセル</button>
                <button v-if="deleted" class="button delete" @click.stop="deleteDataPre">削除</button>
            </div>
        </div>
        <div v-if="kyushokuEditerOpen" class="kyushokuEditer">
            <div class="header-area">
            </div>
            <div class="detail-area">
                <div><input type="date" v-model="kyushokuStartDate"/>&nbsp;〜<input type="date" v-model="kyushokuEndDate"/></div>
                <div><input type="text" class="kyushokuComment" v-model="kyushokuComment" placeholder="備考・理由"/></div>
            </div>
            <div class="footer-area">
                <button @click.stop="kyushokuSave">登録</button>&nbsp;<button @click.stop="kyushokuDelete">削除</button>
            </div>
            <div class="close-win" @click="kyushokuClose_win">✕</div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import utilityMixin  from '@/utils/utilitymix.js';
export default {
    name: 'accountInfoPg',
    mixins: [utilityMixin],
    computed: {
        authority() {
            let res = "";
            switch (this.info.authority) {
                case 99:
                    res = "全権";
                    break;
                case 10:
                    res = "労務管理者";
                    break;
                case 1:
                    res = "一般";
                    break;
                default:
                    res = "　";
            }
            return res;
        },
        postName() {
            return this.info.postName
        },
        useFlag() {
            return (this.info.useFlag) ? "使用中" : "　";
        },
        managed() {
            return (this.param.edited == 1) ? true : false;
        },
        chkEditmode() {
            if (!this.managed) {
                return false;
            } else if (this.editing) {
                return true;
            }
            return false;
        },
        baseClick() {
            return this.$store.getters.operator_baseClick;
        }
    },
    watch: {
        baseClick(nv, ov){
            let flg = nv;
            if (flg != "on") {
                return;
            }
            this.base_click();
            this.$store.dispatch("operator_baseClick", "");
        },
        'sendDt.hireDate'(nval, oval) {
            if ((this.activeAccountId == "") && (this.param.edited == '1')) {
                this.sendDt.startDate = nval;
            }
        }
    },
    data() {
        return {
            accountId: "",
            activeAccountId: "",
            info: {},
            editing: false,
            deleted: false,
            sendDt: {
                employNo:"",
                name1: "",
                name2: "",
                kana1: "",
                kana2: "",
                birthDate: "",
                hireDate: "",
                email: "",
                authority: 1,
                payslipFlag: 0,
                postCd: "",
                useFlag: 1,
                startDate: "",
                endDate: "",
                retireDate: "",
                pwd: "",
                kyushokuList: []
            },
            kyushokuEditerOpen: false,
            kyushokuStartDate: "",
            kyushokuEndDate: "",
            kyushokuComment: "",
            options: [
                {text: "一般", value: 1},
                {text: "労務管理", value: 10},
                {text: "全権", value: 99}
            ],
            positions: [],
            param: {}
        };
    },
    mounted() {
        if (!this.loginActiveCheck()) {
            return;
        }

        this.$nextTick(function() {
            this.$store.commit("operator_headerTitle", "アカウント");
            this.$store.dispatch("operator_baseClick", "");

            this.accountId = this.$store.state.operator.accountId;
            this.activeAccountId = this.$store.state.operator.activeAccountId;
            this.param = this.$store.state.operator.routeParam;
            this.initGetData()
        });
    },
    methods: {
        initGetData() {
            this.getPositionMaster();
            this.getData();
        },
        async getData() {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    updateId: this.accountId
                };
                let ress = await Vue.$cache.post('account/data/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.info = ress.data.data;
                        this.sendDt.employNo = this.info.employNo;
                        let names = this.info.name.split("　");
                        this.sendDt.name1 = names[0];
                        this.sendDt.name2 = names[1];
                        let kanas = this.info.kana.split("　");
                        this.sendDt.kana1 = kanas[0];
                        this.sendDt.kana2 = kanas[1];
                        this.sendDt.birthDate = this.info.birthDate;
                        this.sendDt.hireDate = this.info.hireDate;
                        this.sendDt.email = this.info.email;
                        this.sendDt.authority = this.info.authority;
                        this.sendDt.payslipFlag = this.info.payslipFlag;
                        this.sendDt.postCd = this.info.postCd;
                        this.sendDt.useFlag = this.info.useFlag;
                        this.sendDt.startDate = this.info.startDate;
                        this.sendDt.endDate = this.info.endDate;
                        this.sendDt.retireDate = this.info.retireDate;
                        this.sendDt.pwd = "";
                        this.sendDt.kyushokuList = this.info.kyushokuList;

                        if ((this.activeAccountId == "") && (this.param.edited == '1')) {
                            this.sendDt.useFlag = 1;
                            this.sendDt.authority = 1;
                        }
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
                
                if (this.param.edited == '1') {
                    this.editing = true;
                    if (this.activeAccountId == "") {
                        this.deleted = false;
                    } else {
                        this.deleted = true;
                    }
                } else {
                    this.editing = false;
                    this.deleted = false;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        async getPositionMaster() {
            try {
                this.positions = [];
                let ress = await Vue.$cache.get('master/position/', "", this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.positions = ress.data.data.list;
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        saveDataPre() {
            if (this.chkRequired() == false) {
                return false;
            }

            if (!confirm("登録しますか？")) {
                return false;
            }

            this.saveData();
        },
        async saveData() {
            try {
                let param = {
                    data: this.sendDt,
                    updateId: this.accountId
                };
                let ress = await Vue.$cache.post('account/save/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        if (this.param.edited != '1') {
                            this.getData();
                        }
                        this.cancel();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        deleteDataPre() {
            const mess = "削除しますか？\n\nこの操作は元に戻せません。\nデータを残したい場合は「有効にする」のチェックを外して登録して下さい。";
            if (!confirm(mess)) {
                return false;
            }

            this.deleteData();
        },
        async deleteData() {
            try {
                let param = {
                    accountId: this.activeAccountId,
                    updateId: this.accountId
                };
                let ress = await Vue.$cache.post('account/delete/', param, this.$store);
                if (ress.status == 200) {
                    if (ress.data.status == "ok") {
                        this.cancel();
                    } else {
                        throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                    }
                } else {
                    throw "status:"+ress.status+" /status:"+ress.data.status+" /error:"+ress.data.error;
                }
            } catch (error) {
                //alert('サーバーと接続できませんでした。\n\n時間をおいて再度試してみて下さい。');
                alert('エラーが発生しました。\n\n'+error);
                console.log(error);
            }
        },
        chkRequired() {
            if ((Vue.$utils.factString(this.sendDt.name1) == "")||(Vue.$utils.factString(this.sendDt.name2) == "")) {
                alert("氏名は省略できません");
                return false;
            }
            if ((Vue.$utils.factString(this.sendDt.kana1) == "")||(Vue.$utils.factString(this.sendDt.kana2) == "")) {
                alert("カナ氏名は省略できません");
                return false;
            } else {
                if (Vue.$utils.checkZenKatakana(this.sendDt.kana1+this.sendDt.kana2) == false) {
                    alert("カナ氏名は全角カタカナのみです");
                    return false;
                }
            }
            if (Vue.$utils.factString(this.sendDt.birthDate) == "") {
                alert("生年月日は省略できません");
                return false;
            }
            if (Vue.$utils.factString(this.sendDt.hireDate) == "") {
                alert("入社日は省略できません");
                return false;
            }
            if (Vue.$utils.factString(this.sendDt.email) == "") {
                alert("メールアドレスは省略できません");
                return false;
            } else {
                if (Vue.$utils.checkEmailAddressFormat(this.sendDt.email) == false) {
                    alert("メールアドレスが正しくありません");
                    return false;
                }
            }
            if (Vue.$utils.factString(this.activeAccountId) == "") {
                if (this.sendDt.pwd == "") {
                    alert("パスワードは省略できません");
                    return false;
                }
            }
            if (Vue.$utils.factString(this.sendDt.startDate) == "") {
                alert("利用開始日は省略できません");
                return false;
            }

            return true;
        },
        cvtEmploy(val) {
            if (val == "") {
                val = "　";
            } else if (val == 0) {
                val = "0001";
            } else {
                val = ((1000 + (val - 0)) + "");
            }
            return val;
        },
        cvtName(val) {
            if ((val == "") || (val == undefined)) {
                val = "　";
            }
            return val
        },
        cvtDate(val) {
            if ((val == "") || (val == undefined)) {
                val = "　";
            }
            return val
        },
        cvtEmail(val) {
            if ((val == "") || (val == undefined)) {
                val = "　";
            }
            return val
        },
        editSrat() {
            this.editing = true;
            this.scrollTop(0);
        },
        cancel() {
            if (this.param.edited == '1') {
                this.$router.go(-1);
            } else {
                this.editing = false;

                let names = this.info.name.split("　");
                this.sendDt.name1 = names[0];
                this.sendDt.name2 = names[1];
                let kanas = this.info.kana.split("　");
                this.sendDt.kana1 = kanas[0];
                this.sendDt.kana2 = kanas[1];
                this.sendDt.birthDate = this.info.birthDate;
                this.sendDt.hireDate = this.info.hireDate;
                this.sendDt.email = this.info.email;
                this.sendDt.authority = this.info.authority;
                this.sendDt.payslipFlag = this.info.payslipFlag;
                this.sendDt.postCd = this.info.postCd;
                this.sendDt.useFlag = this.info.useFlag;
                this.sendDt.startDate = this.info.startDate;
                this.sendDt.endDate = this.info.endDate;
                this.sendDt.retireDate = this.info.retireDate;
                this.sendDt.pwd = "";
                
                this.scrollTop(0);
            }
        },
        openKyushokuEditor(item) {
            this.kyushokuEditerOpen = true;
            if (item) {
                this.kyushokuStartDate = item.startDate;
                this.kyushokuEndDate = item.endDate;
                this.kyushokuComment = item.comment;
            }
        },
        kyushokuClose_win() {
            this.kyushokuEditerOpen = false;
            this.kyushokuStartDate = "";
            this.kyushokuEndDate = "";
            this.kyushokuComment = "";
        },
        kyushokuSave() {
            const item = this.sendDt.kyushokuList.find(item => item.startDate == this.kyushokuStartDate);
            if (item) {
                item.endDate = this.kyushokuEndDate;
                item.comment = this.kyushokuComment;
            } else {
                const witem = {};
                witem.startDate = this.kyushokuStartDate;
                witem.endDate = this.kyushokuEndDate;
                witem.comment = this.kyushokuComment;
                this.sendDt.kyushokuList.push(witem);
            }
            const newList = [...this.sendDt.kyushokuList].sort((a, b) => b.startDate.localeCompare(a.startDate));
            this.sendDt.kyushokuList = newList;
            this.kyushokuEditerOpen = false;
        },
        kyushokuDelete() {
            const newList = this.sendDt.kyushokuList.filter(item => item.startDate !== this.kyushokuStartDate);
            this.sendDt.kyushokuList = newList;
            this.kyushokuEditerOpen = false;
        },
        scrollTop(v) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        base_click() {
            //--
        }
    }
}
</script>

<style lang="less">
#accountInfoPg {
    width: 100%;

    .main_area {
        position: relative;
        width: 100%;
        padding: 78px 12% 28px;
        overflow: auto;

        .info_area {
        display: grid;
        grid-template-columns: 1fr 400px;

            .detail_area {

                .titlecell {
                    display: inline;
                    float: left;
                    clear: both;
                    width: 160px;
                    text-align: right;
                    padding-right: 20px;
                    font-weight: bold;
                    margin: 10px 0 8px;
                }

                .datacell {
                    display: inline;
                    float: left;
                    width: 360px;
                    margin: 8px 0;
                    border-bottom: 1px solid #CECECE;

                    .name, .kana {
                        width: 47%;

                        &:first-child {
                            margin-right: 6%;
                        }
                    }

                    .employNo {
                        width: 120px;
                    }

                    .email {
                        width: 100%;
                    }

                    .pwd {
                        width: 100%;
                    }

                    .authority, .position {
                        width: 160px;
                        height: 27px;
                    }

                    input[type='text'], input[type='password'] {
                        /*width: 100%;*/
                        height: 27px;
                    }
                }
            }

            .list-area {
                display: grid;
                grid-template-rows: 120px 8px 1fr;

                .list1 {
                    .title-area {
                        display: flex;
                        justify-content: space-between;

                        .title {
                            font-weight: bold;
                        }

                        .add {
                            cursor: pointer;

                            .icon {
                                width: 14px;
                                fill: var(--foreColor);
                            }
                        }
                    }

                    .kyushoku_area {
                        width: 400px;
                        height: 104px;
                        overflow: auto;
                        border: 1px solid #6D6D6D;

                        .kyushoku {
                            display: grid;
                            padding: 3px 10px;
                            grid-template-columns: 90px 32px 90px 1fr;
                            border-bottom: 1px solid #6D6D6D;
                            align-items: center;

                            div.delim {
                                text-align: center;
                            }

                            div.end {
                                text-align: right;
                            }

                            div.comment {
                                padding-left: 6px;
                            }

                            div {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            clear: both;
            text-align: center;
            padding-top: 22px;

            .hensyu, .save, .cancel, .delete {
                width: 180px;
            }

            .cancel {
                margin-left: 20px;
            }

            .delete {
                margin-left: 20px;
                background-color: red;
                border: 2px solid red;
                color: white;
            }
        }
    }

    .button {
        padding: 3px 12px;
        font-weight: bold;
    }

    .kyushokuEditer {
        position:absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 304px;
        height: 130px;
        padding: 4px 12px 12px;
        box-shadow: 3px 3px 8px 1px rgba(0,0,0,0.2);
        background-color: var(--backColor);
        border: 1px solid #acacac;
        border-radius: 8px;
        display: grid;
        grid-template-rows: 24px 1fr 24px;
        z-index: 99991;

        .detail-area {
            .kyushokuComment {
                width: 100%;
            }

            input[type='text'], input[type='date'] {
                height: 27px;
            }
        }

        .footer-area {
            text-align: center;

            button {
                width: 80px;
            }
        }

        .close-win {
            position: absolute;
            top: 4px;
            right: 4px;
            width: 18px;
            height: 18px;
            text-align: center;
            cursor: pointer;
        }
    }
}

#base_area {
    #header-bar {
        .center_area {
            letter-spacing: 20px;
            text-indent: 20px;
        }
    }
}

@media(max-width:500px) {
    #accountInfoPg {
        .main_area {

            .info_area {
                display: grid;
                grid-template-columns: 1fr 400px;

                .detail_area {

                    .titlecell {
                        float: none;
                        clear: none;
                        width: 160px;
                        text-align: left;
                        padding-right: 0;
                    }

                    .datacell {
                        display: block;
                        float: none;
                        width: 100%;
                        margin: 8px 0 12px;
                    }
                }
            }

            .footer {

                .cancel {
                    margin-left: 0;
                    margin-top: 22px;
                }

                .delete {
                    margin-left: 0;
                    margin-top: 22px;
                    background-color: red;
                    color: white;
                }
            }
        }
    }
}
</style>